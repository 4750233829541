import { type PlacementPay } from "@clipboard-health/contract-worker-app-bff";
import { isDefined } from "@clipboard-health/util-ts";
import { useMemo } from "react";

import { useGetPayTransparencyData } from "../api/useGetPayTransparencyData";
import type { GetPlacementsResponse, PlacementData } from "../types/fetchPlacements.schema";
import type { PayRate } from "../types/payRate";
import { getEstimatedPay } from "./estimatedPay";
import { type NudgeType, usePayTransparencyFlag } from "./usePayTransparencyFlag";

interface UseComputedPayData {
  isLoading: boolean;
  payData: Map<string, PayRate | undefined>;
  maxResults?: number;
  nudge?: NudgeType;
}

function mapPayRange(placement: PlacementData): PayRate {
  return {
    min: placement.attributes.payRange?.minPay?.amountInMinorUnits,
    max: placement.attributes.payRange?.maxPay?.amountInMinorUnits,
    externallyDerived: false,
  };
}

function mapPlacementToCriteria(placement: PlacementData) {
  return {
    workplaceId: placement.relationships.workplace.data.id,
    qualifications: new Set(placement.attributes.workerTypes),
    timeSlots: new Set(placement.attributes.shiftTypes),
    jobTypes: new Set(placement.attributes.jobTypes),
  };
}

function payDataFromPlacements(
  placements: GetPlacementsResponse["data"]
): Map<string, PayRate | undefined> {
  return new Map(placements.map((placement) => [placement.id, mapPayRange(placement)]));
}

function payDataFromPlacementsWithEstimatedPay(
  placements: GetPlacementsResponse["data"],
  payDataByWorkplace: Record<string, PlacementPay[]>,
  obscureEstimates: boolean
): Map<string, PayRate | undefined> {
  return new Map(
    placements.map((placement) => {
      const estimatedPay = getEstimatedPay(mapPlacementToCriteria(placement), payDataByWorkplace);

      if (obscureEstimates && isDefined(estimatedPay)) {
        return [
          placement.id,
          {
            externallyDerived: true,
            obscured: true,
          },
        ];
      }

      return [placement.id, estimatedPay ?? mapPayRange(placement)];
    })
  );
}

export function useComputedPayData(placements: GetPlacementsResponse["data"]): UseComputedPayData {
  const payTransparency = usePayTransparencyFlag();

  const {
    payDataByWorkplace,
    userHasSharedPayData,
    isLoading: isTransparencyLoading,
  } = useGetPayTransparencyData({ enabled: payTransparency.enabled });

  const payData = useMemo<Map<string, PayRate | undefined>>(() => {
    if (
      !payTransparency.enabled ||
      (!userHasSharedPayData && payTransparency.nudge?.kind !== "BLUR")
    ) {
      return payDataFromPlacements(placements);
    }

    if (userHasSharedPayData) {
      return payDataFromPlacementsWithEstimatedPay(placements, payDataByWorkplace, false);
    }

    const obscureEstimates = !userHasSharedPayData && payTransparency.nudge?.kind === "BLUR";
    return payDataFromPlacementsWithEstimatedPay(placements, payDataByWorkplace, obscureEstimates);
  }, [placements, payTransparency, userHasSharedPayData, payDataByWorkplace]);

  return {
    payData,
    isLoading: payTransparency.enabled && isTransparencyLoading,
    nudge: !userHasSharedPayData && payTransparency.enabled ? payTransparency.nudge : undefined,
  };
}
