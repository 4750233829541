import { isDefined } from "@clipboard-health/util-ts";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

import { formatCentsAsUsd } from "../../lib/Money/utils/currency";
import { type Worker } from "../../Worker/api/types";
import { type PlacementDetail } from "./api/useGetPlacement";
import {
  type GetPlacementsForWorkplaceQuery,
  PlacementApplicationStatus,
  type PlacementIncludedInterview,
} from "./types/fetchPlacements.schema";
import { type PayRate } from "./types/payRate";

export const DEFAULT_DISTANCE_FILTER_IN_MILES = 150;

export function formatPayRange(payRate: PayRate | undefined, showPerHour = true) {
  const exactPay = payRate?.exact;
  const minPay = payRate?.min;
  const maxPay = payRate?.max;
  const perHour = showPerHour ? " /hr" : "";

  if (!exactPay && !minPay && !maxPay) {
    return undefined;
  }

  if (!exactPay && minPay && maxPay && minPay !== maxPay) {
    return `${formatCentsAsUsd(minPay)}${perHour} - ${formatCentsAsUsd(maxPay)}${perHour}`;
  }

  return `${formatCentsAsUsd(exactPay ?? minPay ?? maxPay ?? 0)}${perHour}`;
}

export function getPayRangeText(payRate: PayRate | undefined) {
  if (!payRate?.min && !payRate?.max && !payRate?.exact) {
    return "";
  }

  if (isDefined(payRate?.exact)) {
    return "";
  }

  if (isDefined(payRate?.min) && isDefined(payRate?.max) && payRate?.min !== payRate?.max) {
    return "";
  }

  if (isDefined(payRate?.min) && !isDefined(payRate?.max)) {
    return "From";
  }

  if (isDefined(payRate?.max)) {
    return "Up to";
  }

  return "Pay";
}

export const DEFAULT_HIRE_PROFESSIONALS_QUALIFICATION_FILTER = {
  CNA: "CNA",
  "LVN/LPN": "LVN",
  RN: "RN",
  Caregiver: "CAREGIVER",
  HHA: "HHA",
  CHHA: "CHHA",
  "Dental Hygienist": "Dental Hygienist",
  "Dental Assistant": "Dental Assistant",
};
export function getAvailableWorkerTypesToFilter(workerLicensesData: Worker["licensesData"]) {
  if (!workerLicensesData) {
    return ["none"];
  }

  const qualifications = workerLicensesData.map((license) => license.qualification);
  // eslint-disable-next-line unicorn/prefer-spread
  const uniqueQualifications = Array.from(new Set(qualifications));

  const filteredQualifications = uniqueQualifications.filter((qualification) =>
    Object.values(DEFAULT_HIRE_PROFESSIONALS_QUALIFICATION_FILTER).includes(qualification)
  );

  return filteredQualifications.length > 0 ? filteredQualifications : ["none"];
}

export function buildPlacementsFilter({
  filter,
  showApplications,
  worker,
  searchByWorkplaceName,
}: {
  filter: GetPlacementsForWorkplaceQuery["filter"] | undefined;
  showApplications?: boolean;
  worker: Worker;
  searchByWorkplaceName?: string;
}) {
  if (!filter) {
    return undefined;
  }

  return {
    ...(showApplications
      ? { "applications.status": Object.values(PlacementApplicationStatus).join(",") }
      : {}),
    ...filter,
    distanceInMiles:
      isDefined(filter?.distanceInMiles) && filter.distanceInMiles > 0
        ? filter.distanceInMiles
        : DEFAULT_DISTANCE_FILTER_IN_MILES,
    workerTypes: isDefined(filter?.workerTypes)
      ? filter.workerTypes
      : getAvailableWorkerTypesToFilter(worker.licensesData).join(","),
    ...(searchByWorkplaceName ? { workplaceName: searchByWorkplaceName } : undefined),
  };
}

export function getFormattedInterviewDate(interview: PlacementIncludedInterview, timezone: string) {
  const startDateInTimezone = utcToZonedTime(interview.attributes.start, timezone);
  const endDateInTimezone = utcToZonedTime(interview.attributes.end, timezone);

  return `${format(startDateInTimezone, "MMM d, yyyy 'from' h:mm a")} - ${format(
    endDateInTimezone,
    "h:mm a"
  )}`;
}

export function getApplyButtonLabel(hasApplied: boolean, interview: PlacementDetail["interview"]) {
  if (hasApplied && isDefined(interview)) {
    return "Booked interview";
  }

  if (hasApplied && !isDefined(interview)) {
    return "Applied";
  }

  return "Apply to this Job";
}
