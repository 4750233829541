import { type PlacementPay } from "@clipboard-health/contract-worker-app-bff";

import { JobType, ShiftNameType } from "../../PlacementCandidate/types";
import { useGetPayTransparencyData } from "../api/useGetPayTransparencyData";
import { type PlacementData } from "../types/fetchPlacements.schema";
import { type PayRate } from "../types/payRate";
import { getEstimatedPay } from "./estimatedPay";

const SLOTS = [ShiftNameType.AM, ShiftNameType.PM, ShiftNameType.NOC] as const;
const JOB_TYPES = [
  JobType.FULL_TIME,
  JobType.PART_TIME,
  JobType.PER_DIEM,
  JobType.WEEKEND_WARRIOR,
] as const;

function generateRatesForJobType(
  qualification: string,
  jobType: JobType,
  payTransparencyData: Record<string, PlacementPay[]> | undefined,
  placement: PlacementData | undefined
): Array<{ slot: ShiftNameType; rate: PayRate | undefined }> {
  if (!placement || !payTransparencyData) {
    return [];
  }

  return SLOTS.map((slot) => ({
    slot,
    rate: getEstimatedPay(
      {
        workplaceId: placement.relationships.workplace.data.id,
        qualifications: new Set([qualification]),
        timeSlots: new Set([slot]),
        jobTypes: new Set([jobType]),
      },
      payTransparencyData
    ),
  }));
}

export interface UseTransparencyTableData {
  tableData: Record<string, Array<{ slot: ShiftNameType; rate: PayRate | undefined }>>;
}

export function useTransparencyTableData(
  qualification: string,
  placement: PlacementData | undefined
): UseTransparencyTableData {
  const { payDataByWorkplace } = useGetPayTransparencyData();

  return {
    tableData: Object.fromEntries(
      JOB_TYPES.map((jobType) => [
        jobType,
        generateRatesForJobType(qualification, jobType, payDataByWorkplace, placement),
      ])
    ),
  };
}
