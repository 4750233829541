import { type PlacementPay } from "@clipboard-health/contract-worker-app-bff";

import { intersection } from "../../../lib/utils/set";
import { type PayRate } from "../types/payRate";

const TIME_SLOT_ORDER = ["am", "pm", "noc"];
const JOB_TYPE_ORDER = ["full_time", "part_time", "prn", "weekend_warrior"];

interface EstimatedPaySearchCriteria {
  workplaceId: string;
  qualifications: Set<string>;
  timeSlots: Set<string>;
  jobTypes: Set<string>;
}

function isMatchingPayRate(
  payRate: PlacementPay,
  searchCriteria: EstimatedPaySearchCriteria
): boolean {
  const payRateQualifications = new Set<string>(payRate.attributes.qualifications);
  const payRateTimeSlots = new Set<string>(payRate.attributes.timeSlots);
  const payRateJobTypes = new Set<string>(payRate.attributes.jobTypes);

  return (
    intersection(payRateQualifications, searchCriteria.qualifications).size > 0 &&
    intersection(payRateTimeSlots, searchCriteria.timeSlots).size > 0 &&
    intersection(payRateJobTypes, searchCriteria.jobTypes).size > 0
  );
}

function sortPayRatesBySlotAndType(
  a: PlacementPay,
  b: PlacementPay,
  placementTimeSlots: Set<string>,
  placementJobTypes: Set<string>
) {
  // Get the first matching time slot for each pay rate
  const aTimeSlot = a.attributes.timeSlots.find((ts) => placementTimeSlots.has(ts)) ?? "";
  const bTimeSlot = b.attributes.timeSlots.find((ts) => placementTimeSlots.has(ts)) ?? "";

  // Compare time slots
  const timeSlotDiff = TIME_SLOT_ORDER.indexOf(aTimeSlot) - TIME_SLOT_ORDER.indexOf(bTimeSlot);
  if (timeSlotDiff !== 0) {
    return timeSlotDiff;
  }

  // If time slots are equal, compare job types
  const aJobType = a.attributes.jobTypes.find((jt) => placementJobTypes.has(jt)) ?? "";
  const bJobType = b.attributes.jobTypes.find((jt) => placementJobTypes.has(jt)) ?? "";

  return JOB_TYPE_ORDER.indexOf(aJobType) - JOB_TYPE_ORDER.indexOf(bJobType);
}

function zeroToUndefined(value: number | undefined) {
  return value === 0 ? undefined : value;
}

function findMatchingPayRate(
  searchCriteria: EstimatedPaySearchCriteria,
  payRatesForWorkplace: PlacementPay[]
): PlacementPay | undefined {
  const matchingPayRates = payRatesForWorkplace
    .filter((payRate) => isMatchingPayRate(payRate, searchCriteria))
    .sort((a, b) =>
      sortPayRatesBySlotAndType(a, b, searchCriteria.timeSlots, searchCriteria.jobTypes)
    );

  return matchingPayRates[0];
}

export function getEstimatedPay(
  searchCriteria: EstimatedPaySearchCriteria,
  payByWorkplace: Record<string, PlacementPay[]>
): PayRate | undefined {
  const payRatesForWorkplace = payByWorkplace[searchCriteria.workplaceId];
  if (!payRatesForWorkplace) {
    return undefined;
  }

  const matchingPayRate = findMatchingPayRate(searchCriteria, payRatesForWorkplace);
  if (matchingPayRate) {
    const payRateQualifications = new Set<string>(matchingPayRate.attributes.qualifications);
    const payRateTimeSlots = new Set<string>(matchingPayRate.attributes.timeSlots);
    const payRateJobTypes = new Set<string>(matchingPayRate.attributes.jobTypes);

    return {
      exact: zeroToUndefined(matchingPayRate.attributes.exactPayRate),
      min: zeroToUndefined(matchingPayRate.attributes.minPayRate),
      max: zeroToUndefined(matchingPayRate.attributes.maxPayRate),
      externallyDerived: true,
      matchedQualifications: intersection(searchCriteria.qualifications, payRateQualifications),
      matchedTimeSlots: intersection(searchCriteria.timeSlots, payRateTimeSlots),
      matchedJobTypes: intersection(searchCriteria.jobTypes, payRateJobTypes),
      obscured: false,
    };
  }

  return undefined;
}
