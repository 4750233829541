export const PLACEMENTS_BASE_PATH = "placements";

export const PLACEMENT_WORKPLACE_PROFILE_PATH = "workplaces/:workplaceId/profile";
export const PLACEMENT_WORKPLACE_JOBS_PATH = "workplaces/:workplaceId/jobs";
export const PLACEMENT_WORKPLACE_REVIEWS_PATH = "workplace/:workplaceId/reviews";
export const PLACEMENT_WORKPLACE_REVIEWS_COMMENT_PATH = `workplace/:workplaceId/comment/:commentId`;
export const PLACEMENT_WORKPLACE_LEAVE_REVIEW_PATH = `workplace/:workplaceId/reviews/leave-review`;

export const PLACEMENT_PATHS = {
  ROOT: PLACEMENTS_BASE_PATH,
  DETAILS: `/home-v2/${PLACEMENTS_BASE_PATH}/:placementId/placement-candidate/:placementCandidateId?`,
  DETAILS_WITHOUT_PLACEMENT_CANDIDATE: `/home-v2/${PLACEMENTS_BASE_PATH}/:placementId`,
  CHAT: `/home-v2/${PLACEMENTS_BASE_PATH}/:placementId/workplace/:workplaceId/chat?source=:source`,
  WORKPLACE_PROFILE: `/home-v2/${PLACEMENTS_BASE_PATH}/${PLACEMENT_WORKPLACE_PROFILE_PATH}`,
  WORKPLACE_JOBS: `/home-v2/${PLACEMENTS_BASE_PATH}/${PLACEMENT_WORKPLACE_JOBS_PATH}`,
  WORKPLACE_REVIEWS: `/home-v2/${PLACEMENTS_BASE_PATH}/${PLACEMENT_WORKPLACE_REVIEWS_PATH}`,
  WORKPLACE_REVIEWS_COMMENT: `/home-v2/${PLACEMENTS_BASE_PATH}/${PLACEMENT_WORKPLACE_REVIEWS_COMMENT_PATH}`,
  WORKPLACE_LEAVE_REVIEW: `/home-v2/${PLACEMENTS_BASE_PATH}/${PLACEMENT_WORKPLACE_LEAVE_REVIEW_PATH}`,
} as const;

export function getPlacementDetailsPath(placementId: string, placementCandidateId: string): string {
  return PLACEMENT_PATHS.DETAILS.replace(":placementId", placementId).replace(
    ":placementCandidateId",
    placementCandidateId
  );
}

export function getPlacementDetailsPathWithoutPlacementCandidate(placementId: string): string {
  return PLACEMENT_PATHS.DETAILS_WITHOUT_PLACEMENT_CANDIDATE.replace(":placementId", placementId);
}

export function getPlacementChatPath(
  placementId: string,
  workplaceId: string,
  source:
    | "application-success-details"
    | "chat-with-workplace"
    | "chat-with-placement-candidate-via-chat-channels"
    | "chat-with-placement-candidate-via-my-shifts"
): string {
  return PLACEMENT_PATHS.CHAT.replace(":placementId", placementId)
    .replace(":workplaceId", workplaceId)
    .replace(":source", source);
}
